<!--
 * @Author: 吴伟 15711376175@163.com
 * @Date: 2025-01-21 17:54:30
 * @LastEditors: 吴伟 15711376175@163.com
 * @LastEditTime: 2025-02-12 09:36:35
 * @FilePath: /ww-swisstyle/src/views/team.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="team-container">
        <div class="banner">
            <img src="../assets/team/banner.png" alt="" class="img-fluid">
        </div>
        <!-- <div class="about">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="about-content">
                            <h2>关于Swisstyle</h2>
                            <div class="desc">
                                <p>50年来，我们致力于为客户提供与时俱进具有竞争力的品牌和包装设计解决方案；</p>
                                <p>致力于成为客户的第一选择，客户乐于购买我们的策划方针和解决方案；</p>
                                <p>客户不需要平庸的建议，他们需要的是不可替代的有统治力的针对他们特殊问题的解决方案。</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="team-img">
                            <img src="../assets/team/image.png" alt="团队照片" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="team-list" v-for="item in teamMembers" :key="item.id">
            <div class="container">
                <div class="member-info">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="member-img">
                                <img :src="item.imageUrl" alt="Winfried Geymeier" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="member-content">
                                <h2>{{ item.productCnName }}</h2>
                                <h3>{{ item.productEnName }}</h3>
                                <div class="desc">
                                    <p class="zh" v-if="item.productCnDesc1" >{{item.productCnDesc1}}</p>
                                    <p class="zh" v-if="item.productCnDesc2" >{{item.productCnDesc2}}</p>
                                    <p class="zh" v-if="item.productCnDesc3" >{{item.productCnDesc3}}</p>
                                    <p class="zh" v-if="item.productCnDesc4" >{{item.productCnDesc4}}</p>
                                    <p class="zh" v-if="item.productCnDesc5" >{{item.productCnDesc5}}</p>
                                    <p class="zh" v-if="item.productCnDesc6" >{{item.productCnDesc6}}</p>
                                    <p v-if="item.productEnDesc1">{{ item.productEnDesc1 }}</p>
                                    <p v-if="item.productEnDesc2">{{ item.productEnDesc2 }}</p>
                                    <p v-if="item.productEnDesc3">{{ item.productEnDesc3 }}</p>
                                    <p v-if="item.productEnDesc4">{{ item.productEnDesc4 }}</p>
                                    <p v-if="item.productEnDesc5">{{ item.productEnDesc5 }}</p>
                                    <p v-if="item.productEnDesc6">{{ item.productEnDesc6 }}</p>
                                  
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api';

export default {
    name: 'Team',
    data() {
        return {
            teamMembers: []
        }
    },
    methods: {
        async fetchTeamData() {
            try {
                const data = await api.getProductList('team');
                data.rows.forEach(item => {
                    item.imageUrl = process.env.VUE_APP_OSS_URL + item.imageUrl
                })
                this.teamMembers = data.rows;
            } catch (error) {
                console.error('获取团队数据失败:', error);
            }
        }
    },
    mounted() {
        this.fetchTeamData();
    }
}
</script>

<style lang="less" scoped>
.team-container {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    .banner {
        width: 100%;
        img {
            width: 100%;
            display: block;
        }
    }
    .about {
        padding: 80px 0;
        background: #ebebeb;

        .about-content {
            h2 {
                font-size: 32px;
                color: #333;
                margin-bottom: 30px;
                font-weight: 500;
            }

            .desc {
                p {
                    font-size: 16px;
                    color: #666;
                    line-height: 1.8;
                    margin-bottom: 20px;
                }
                
            }
        }

        .team-img {
            overflow: hidden;
            box-shadow: 0 10px 30px rgba(0,0,0,0.1);
        }
    }

    .team-list {
        padding: 60px 0;
        background: #fff;
        .container {
            padding-bottom: 40px;
            border-bottom: 1px solid;
        }
            &:last-child {
                .container {
            
                border-bottom: none
                }
            }
        .member-info {
            .member-img {
                padding-right: 30px;
            }

            .member-content {
                h2 {
                    font-size: 42px;
                    color: #333;
                    margin-bottom: 10px;
                    font-weight: 400;
                }

                h3 {
                    font-size: 24px;
                    color: #666;
                    margin-bottom: 30px;
                    font-weight: 400;
                }

                .desc {
                    p {
                        font-size: 16px;
                        color: #666;
                        line-height: 1.8;
                        margin-bottom: 20px !important;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .team-container {
        .about {
            padding: 40px 0;

            .about-content {
                margin-bottom: 30px;
                text-align: center;

                h2 {
                    font-size: 28px;
                }
            }
        }

        .team-list {
            padding: 40px 0;

            .member-info {
                .member-img {
                    padding-right: 0;
                    margin-bottom: 30px;
                }

                .member-content {
                    text-align: center;

                    h2 {
                        font-size: 32px;
                    }

                    h3 {
                        font-size: 20px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
</style>